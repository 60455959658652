import React from "react";
import { HashLink as Link } from "react-router-hash-link";
import styles from './../style/partnering.module.css';
import allogene from './../assets/img/allogeneLogo.png'
import pennmedicine from './../assets/img/pennMedicineLogo.png'
import poltregLogo from './../assets/img/POLTREG_logo.png';

const Partnering = () => {
  return (
    <div className={styles.partneringContent}>
      <div className={styles.partneringTitle}>Collaborations and technology licensing</div>
      <div className={styles.partneringText}>
        Antion Biosciences is applying its proprietary cell engineering technologies to develop breakthrough treatments for challenging cancer indications and other complex disease. The versatility and broad applicability of our technologies allows to treat numerous diseases across multiple therapeutic areas. We welcome collaborations with research institutions, patient organisations and associations, biotech companies and pharma, to bring our next-generation platform to many more patients in need.
      </div>
      <div className={styles.partneringContactButton}><Link to="/contact">[ Contact us ]</Link></div>
      <div className={styles.currentPartnersSection}>
        {/* POLTREG */}
        <div className={styles.currentPartnerBox}>
         {/* <div className={styles.currentPartnerName}>PolTREG</div> */}
          <img className={styles.currentPartnerLogo} src={poltregLogo} alt="Poltreg"/>
          <div className={styles.currentPartnerDescription}>Antion Biosciences SA and PolTREG S.A. have partnered to develop next-generation allogeneic CAR-Treg therapies, combining Antion's microRNA (miRNA)-mediated gene silencing platform with PolTREG's clinical expertise in Treg technologies. This collaboration aims to create scalable, "off-the-shelf" treatments with enhanced immunomodulatory activity, offering transformative potential for autoimmune and neurodegenerative diseases.</div>
        </div>
        {/* ALLOGENE */}
        <div className={styles.currentPartnerBox}>
          {/* <div className={styles.currentPartnerName}>Allogene Therapeutics</div> */}
          <img className={styles.currentPartnerLogo} src={allogene} alt="Allogene Therapeutics"/>
          <div className={styles.currentPartnerDescription}>Antion entered into a collaboration and license agreement with Allogene for use of our proprietary miRNA technology. Allogene deploys Antion’s multiplex miRNA with other technologies to enable next-generation strategies for immune evasion and other advances in allogeneic CAR T-cell development.</div>
        </div>
        {/* UPENN */}
        <div className={styles.currentPartnerBox}>
          {/* <div className={styles.currentPartnerName}>Penn Medicine</div> */}
          <img className={styles.currentPartnerLogo} src={pennmedicine} alt="Penn Medicine"/>
          <div className={styles.currentPartnerDescription}>In a collaborative project with the June Lab at the Center for Cellular Immunotherapies, Antion transferred proprietary miRNA gene constructs for the development of multiplex engineered chimeric antigen receptor (CAR) T-cells. Our gene constructs have been applied to engineer allogeneic and hypoimmunogenic CAR T-cells for targeting of both hematological and solid tumor cancers.</div>
        </div>
      </div>
    </div >
  )
}

export default Partnering;